@font-face {
    font-family: 'Delicious Handrawn';
    src: url('./DeliciousHandrawn-Regular.ttf') format('truetype'); /* Update the file format if using a different file type */
    font-display: swap;
  }
  
@font-face {
  font-family: 'GT-Alpina';
  src: url('../fonts/GT-Alpina-Standard-Medium-Trial.otf') format('opentype');
  font-display: swap;
}