
button {
  background-color: #FFC107; /* Yellow */
  border: none;
  border-radius: calc(1vw + 25px); /* Pill shape, updated to use calc() */
  color: #000; /* Text color */
  cursor: pointer;
  font-size: calc(1vw + 20px); /* Font size updated to use calc() */
  padding: calc(1vw + 5px) calc(2vw + 10px); /* Adjust the padding for medium size, updated to use calc() */
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

button:disabled {
  background-color: #707070; /* Grey */
  cursor: default;
}

button:disabled:hover {
  background-color: #707070; /* Grey */
  cursor: default;
}

button:hover {
  background-color: #FFD54F; /* Lighter yellow on hover */
}

.container {
  max-width: 100%;
  min-width: 350px;
  margin: 0 auto;
  position: relative;
}

.gryfn {
  background-color: transparent;
  font-family: "Montserrat";
  font-weight: 50;
  font-size: 3em;
  padding: 10px;
  display: flex;
  justify-content: left;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

@media screen and (max-width: 350px) {
  .gryfn{
    top: 0;
  }
}

.logo-text-container {
  display: flex;
  align-items: center;
  gap: 10px;
  text-shadow: 1px 1px 2px #eed859;
  color: #242424;
  height: 75px;
}

.logo {
  height: 75px;
  width: auto;
}

.logo-report-text {
  font-family: "Montserrat";
  font-weight: 50;
  font-size: 3em;
}

nav {
  display: flex;
  gap: 10px;
  justify-content: right;
  align-items: center;
  position: absolute;
  top: 10px;
  left: 0;
  right: 10px;
  z-index: 100;
}

nav a {
  background: none;
  color: #fff;
  padding: 5px 10px;
  text-decoration: none;
  border-radius: 15px;
  font-size: 18px; /* Increased font size */
  transition: background 0.3s ease;
}

nav a:hover {
  background: rgba(255, 255, 255, 0.1);
}

/* Main content */
main {
  text-align: center;
  position: relative;
  z-index: 1;
}

.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.custom-p-error {
  margin: 2vh;
  font-size: 2vw;
}

.tutorial-text {
  font-size: 20px;
  line-height: 30px;
}

audio::-webkit-media-controls-play-button {
  background-color: transparent;
  border-radius: 50%;
}

.dynamic-play-button::before {
  content: '';
  display: inline-block;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.1); /* Example overlay style */
  animation: click 0.2s ease;
}


/* Animation definition */
@keyframes click {
0% { transform: scale(1); }
50% { transform: scale(0.9); }
100% { transform: scale(1); }
}